
document.addEventListener("turbolinks:load", function() {

  $(document).find('#request_demo, #request_demo_footer, #request_demo_hm').on('click', function () {
    $(document.body).css('overflow', 'hidden');
    $('#demo-modal').fadeIn("slow");
  });

  $(document).find('#request_demo_hm').on('click', function () {
    $('.mobile-nav').fadeOut("slow");
    $('.hamburger').removeClass('is-active');
    $('.mobile-nav').removeClass('is-active');
  });

  $(document).find('.close-modal, .cancel-demo-modal').on('click', function () {
    $(document.body).css('overflow', 'auto')
    $('#demo-modal').fadeOut("slow");
  });

  $('#validate_empty_space_for_name').on('input',function(){
    var txt = $('#validate_empty_space_for_name')
    if (txt.val().trim().length == 0) {
      txt[0].setCustomValidity("Please fill in this field.");
      txt[0].reportValidity();
    }
    else { 
      txt[0].setCustomValidity(""); 
    }
  });

  $('#validate_empty_space_for_phone').on('input',function(){
    var txt = $('#validate_empty_space_for_phone')
    if (txt.val().trim().length == 0) {
      txt[0].setCustomValidity("Please fill in this field.");
      txt[0].reportValidity();
    }
    else { 
      txt[0].setCustomValidity(""); 
    }
  });
});
