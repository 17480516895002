$(document).on('turbolinks:load', function() {

  function stopVideo(tag) {
    video = $(document).find(tag);
    play = video.next().find('.play');
    pause = video.next().find('.pause');
    play.show();
    pause.hide();
    video.get(0).pause();
  }

  function stopAllVideos() {
    tags = ['.video1', '.video2', '.video3', '.video4']
    tags.forEach(stopVideo);
  }

  $(document).on('click', '.play', function(event) {
    stopAllVideos();
    video = $(this).parent().prev().get(0).play();
    pause = $(this).next();
    $(this).hide();
    pause.show();
  });

  $(document).on('click', '.pause', function(event) {
    video = $(this).parent().prev().get(0).pause();
    play = $(this).prev();
    $(this).hide();
    play.show();
  });

  $('.video-tag').on('ended',function(){
    $(this).get(0).currentTime = 0
    play = $(this).next().find('.play');
    pause = $(this).next().find('.pause');
    play.show();
    pause.hide();
  });

  $('.best-practices-root .faq').on('click', '.question', function(e) {
    var text = $(this).find('.answer-text');
    if($(this).hasClass('open')) {
      text.hide();
      $(this).removeClass('open');
    } else {
      text.show();
      $(this).addClass('open');
    }
  });
});
