$(document).on('turbolinks:load', function() {
  $(function() {
    var gdpr_cookie = document.cookie.split(';').some(function(item) {
      return item.trim().indexOf('gdpr=') == 0
    });

    if (gdpr_cookie) {
      $("[data-cookie-prompt]").hide();
    } else {
      $("[data-cookie-prompt]").show();
    }

    // $('[data-cookie-accept]').on('click', function(e) {
    //   e.preventDefault();
    //   document.cookie = "gdpr=1"
    //   $("[data-cookie-prompt]").hide();
    // });

    $('[data-cookie-accept]').on('click', function(e) {
      var now = new Date();
      now.setTime(+ now + (365 * 86400000)); /* 24 * 60 * 60 * 1000 */
      e.preventDefault();
      document.cookie = "gdpr=1;expires="+now.toGMTString()+";path=/";
      $("[data-cookie-prompt]").hide();
    });
  });
});