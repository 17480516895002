$(document).on('turbolinks:load', function() {
  $('#btn-video').on('click', function(e) {
    e.preventDefault();
    modal = document.getElementById("video-modal");
    div = document.getElementById("iframe-sizer");
    iframe = div.getElementsByTagName("iframe")[0].contentWindow;
    iframe.postMessage('{"method":"play"}', '*');
    modal.style.display = "flex";
  });

  $('#video-modal').on('click', function(e) {
    modal = document.getElementById("video-modal");
    div = document.getElementById("iframe-sizer");
    iframe = div.getElementsByTagName("iframe")[0].contentWindow;
    iframe.postMessage('{"method":"pause"}', '*');
    modal.style.display = "none";
  });

  $(document).on('click', '#video-modal', function(event) {
    if (event.target == modal) {
      iframe.postMessage('{"method":"pause"}', '*');
      modal.style.display = "none";
    }
  });

  $('.success-stories').on('click', '.btn-trigger', function(e) {
    e.preventDefault();
    let storiesContainer = $('.stories-container')
    var text_1 =  $('.success-stories').find('#value_1');
    var text_2 =  $('.success-stories').find('#value_2');
    var text_3 =  $('.success-stories').find('#value_3');
    var text_4 =  $('.success-stories').find('#value_4');
    var text_5 =  $('.success-stories').find('#value_5');
    var text_6 = $('.success-stories').find('#value_6');
    var text_7 =  $('.success-stories').find('#value_7');
    var text_8 =  $('.success-stories').find('#value_8');
    var img_a = $('.success-stories').find('#img_a');
    var img_b = $('.success-stories').find('#img_b');
    var img_c = $('.success-stories').find('#img_c');
    let storiesText = $('.success-stories').find('.stories-text h6');
    let highlightText = $('.success-stories').find('.stories-text h6 span');
    let learnMoreButton = $('#learn-more-button')
    let learnMoreMobileButton = $('#learn-more-mobile-button')
    let learnMoreLink = learnMoreButton.find('a')
    let learnMoreMobileLink = learnMoreMobileButton.find('a')

    if(text_1.attr('data-str') == 1) {
      storiesContainer.css('background-color', '#29CDDD')
      storiesContainer.find('.gif-container img').css('border', '4px solid #FFE818')
      text_1.text('$20,318');
      text_1.removeClass('yellow-text')
      text_1.css('color', '#F7FAFC')
      text_2.text('Total Raised')
      text_2.css('color', '#F7FAFC')
      text_3.text('475%');
      text_3.css('color', '#FFE818')
      text_4.text('Over Previous Record');
      text_4.css('color', '#F7FAFC')
      text_5.text('$3,500');
      text_5.css('color', '#F7FAFC')
      text_6.text('Previous Record')
      text_6.css('color', '#F7FAFC')
      text_7.text('Schultz JH Bulldogs');
      text_7.css('color', '#F7FAFC')
      text_8.text('Waller, TX');
      text_8.css('color', '#F7FAFCA3')
      img_a.attr("src", image_2_a);
      img_b.attr("src", image_2_b);
      img_c.attr("src", image_2_c);
      storiesText.html('<span style="color: #FFE818">"This is bananas!"</span> That was what Band Director Danielle A. said when her band beat their previous fundraising record in the first week.')
      storiesText.css('color', '#FFFFFF')
      highlightText.css('color', '#FFE818')
      learnMoreMobileButton.css('visibility', 'visible')
      learnMoreButton.css('visibility', 'visible')
      learnMoreLink.attr('href', 'https://blog.widdyup.com/this-was-bananas/')
      learnMoreMobileLink.attr('href', 'https://blog.widdyup.com/this-was-bananas/')
      text_1.attr("data-str", 2);
      return true;
    }
    if(text_1.attr('data-str') == 2) {
      storiesContainer.css('background-color', '#FFE818')
      storiesContainer.find('.gif-container img').css('border', '4px solid #6E5FFC')
      text_1.text('$50,850');
      text_1.css('color', '#13242F')
      text_2.text('Total Raised')
      text_2.css('color', '#13242FA3')
      text_3.text('$76.51');
      text_3.css('color', '#6E5FFC')
      text_4.text('Average Donation');
      text_4.css('color', '#13242FA3')
      text_5.text('664');
      text_5.css('color', '#13242F')
      text_6.text('Donations')
      text_6.css('color', '#13242FA3')
      text_7.text('Foster HS Band');
      text_7.css('color', '#13242F')
      text_8.text('Richmond, TX');
      text_8.css('color', '#13242FA3')
      img_a.attr("src", image_3_a);
      img_b.attr("src", image_3_b);
      img_c.attr("src", image_3_c);
      storiesText.html('The Foster Band was raising money to pay for a trip to perform in the Disney World Magic Kingdom parade. When it was all over <span style="color: #6E5FFC">they immediately signed up to do it again</span>!')
      storiesText.css('color', '#000000')
      highlightText.css('color', '#6E5FFC')
      learnMoreMobileButton.css('visibility', 'hidden')
      learnMoreButton.css('visibility', 'hidden')
      text_1.attr("data-str", 3);
      return true;
    }
    if(text_1.attr('data-str') == 3) {
      storiesContainer.css('background-color', '#F9713F')
      storiesContainer.find('.gif-container img').css('border', '4px solid #FFE818')
      text_1.text('$8,176');
      text_1.css('color', '#F7FAFC')
      text_2.text('Total Raised')
      text_2.css('color', '#F7FAFC')
      text_3.text('$1,100');
      text_3.css('color', '#F7FAFC')
      text_4.text('Previous Fundraising Record');
      text_4.css('color', '#F7FAFC')
      text_5.text('643%');
      text_5.css('color', '#FFE818')
      text_6.text('Over Previous Record')
      text_6.css('color', '#F7FAFC')
      text_7.text('Keefer Crossing Orchestra');
      text_7.css('color', '#F7FAFC')
      text_8.text('New Caney, TX');
      text_8.css('color', '#F7FAFCA3')
      img_a.attr("src", image_4_a);
      img_b.attr("src", image_4_b);
      img_c.attr("src", image_4_c);
      storiesText.html('Orchestra Director Kellie D. liked beating her previous fundraising record but LOVED that <span style="color: #FFE818">WiddyUP doubled as a marketing campaign</span> for the band.')
      storiesText.css('color', '#FFFFFF')
      highlightText.css('color', '#FFE818')
      learnMoreMobileButton.css('visibility', 'visible')
      learnMoreButton.css('visibility', 'visible')
      learnMoreLink.attr('href', 'https://blog.widdyup.com/success-story-keefer-crossing-ms-orchestra/')
      learnMoreMobileLink.attr('href', 'https://blog.widdyup.com/success-story-keefer-crossing-ms-orchestra/')
      text_1.attr("data-str", 4);
      return true;
    }
    if(text_1.attr('data-str') == 4) {
      storiesContainer.css('background-color', '#6E5FFC')
      storiesContainer.find('.gif-container img').css('border', '4px solid #FFE818')
      text_1.text('$49,400');
      text_1.addClass('yellow-text')
      text_2.text('Total Raised')
      text_2.css('color', '#F7FAFC')
      text_3.text('247%');
      text_3.css('color', '#F7FAFC')
      text_4.text('% Of Goal');
      text_4.css('color', '#F7FAFC')
      text_5.text('$20,000');
      text_5.css('color', '#F7FAFC')
      text_6.text('Fundraising Goal')
      text_6.css('color', '#F7FAFC')
      text_7.text('Hamilton MS Band');
      text_7.css('color', '#F7FAFC')
      text_8.text('Cypress, TX');
      text_8.css('color', '#F7FAFCA3')
      img_a.attr("src", image_1_a);
      img_b.attr("src", image_1_b);
      img_c.attr("src", image_1_c);
      storiesText.html('"We didn’t have to pass out any product, collect funds or make multiple deposits. <span style="color: #FFE818">Best fundraiser ever!</span>"   - Jaime C. / Band Director')
      storiesText.css('color', '#F7FAFC')
      highlightText.css('color', '#FFE818')
      text_1.attr("data-str", 1);
      learnMoreMobileButton.css('visibility', 'hidden')
      learnMoreButton.css('visibility', 'hidden')
      return true;
    }
  });

  $('#faq-component').on('click', '.question-text', function(e) {
    var text = $(this).next();
    if (text.is(":hidden")){
      text.show();
    } else {
      text.hide();
    }
  });

  $('.hamburger').on('click', function(e) {
    
    $('.hamburger').toggleClass('is-active');
    $('.mobile-nav').toggleClass('is-active');
  });

  let testimonialModal = $('#testimonial-modal')
  let body = $(document.body)

  $('#tell-me-more').on('click', function(e){
    body.css('overflow', 'hidden')
    testimonialModal.css('display', 'block')
  })

  $(window).on('click', function (event) {
    if (event.target === testimonialModal.get(0)) {
      body.css('overflow', 'auto')
      testimonialModal.css('display', 'none')
    }
  })

  $('.close-testimonial-modal').on('click', function () {
    testimonialModal.hide()
    body.css('overflow', 'auto')
  })

  $('#aaab').on('click', function (){
    console.log('aeee')
  })

  $(document).find('.video').on('click', function () {
    let videoElement = $(this).children().get(0);
    if (videoElement.paused) {
      videoElement.play();
      $(".video-info").hide();
    } else {
      videoElement.pause();
      $(".video-info").show();
    }
  });

  if (testimonialModal.attr('data-testimonial-id') === '1'){
    hidePreviousButton()
  }

  if (testimonialModal.attr('data-testimonial-id') === '3'){
    hideNextButton()
  }

  $('#testimonial-previous').on('click', function(e){
    e.preventDefault();
    let testimonialModal = $('#testimonial-modal')
    let testimonialText = testimonialModal.find('#testimonial-text')
    let testimonialVideo = testimonialModal.find('#testimonial-video')

    if(testimonialModal.attr('data-testimonial-id') == 3) {
      testimonialText.text('Kellie, the Orchestra Director at Keefer Crossing, loved raising more money than she ever had in her 18 years as a fundraiser. But what she loved the most about WiddyUP was how the videos doubled as the perfect marketing tool for her group.')
      testimonialVideo.attr("src", testimonial_2)
      testimonialVideo.attr("poster", testimonial_2_thumb)
      testimonialModal.attr("data-testimonial-id", 2);
      $(".video-info").show();
      showPreviousButton()
      showNextButton()
      return true;
    }

    if(testimonialModal.attr('data-testimonial-id') == 2) {
      testimonialText.text('The Schultz MS Bulldog Band absolutely crushed their fundraiser. They beat their fundraising goal by whopping 480% and their kids had a ton of fun. Hear what Band Directors Alice and Matthew had to say about their experience.')
      testimonialVideo.attr("src", testimonial_1)
      testimonialVideo.attr("poster", testimonial_1_thumb)
      testimonialModal.attr("data-testimonial-id", 1);
      $(".video-info").show();
      showNextButton()
      hidePreviousButton()
      return true;
    }
  })

  $('#testimonial-next').on('click', function(e){
    e.preventDefault();
    let testimonialModal = $('#testimonial-modal')
    let testimonialText = testimonialModal.find('#testimonial-text')
    let testimonialVideo = testimonialModal.find('#testimonial-video')

    if(testimonialModal.attr('data-testimonial-id') == 1) {
      testimonialText.text('Kellie, the Orchestra Director at Keefer Crossing, loved raising more money than she ever had in her 18 years as a fundraiser. But what she loved the most about WiddyUP was how the videos doubled as the perfect marketing tool for her group.')
      testimonialVideo.attr("src", testimonial_2)
      testimonialVideo.attr("poster", testimonial_2_thumb)
      testimonialModal.attr("data-testimonial-id", 2);
      $(".video-info").show();
      showPreviousButton()
      showNextButton()
      return true;
    }

    if(testimonialModal.attr('data-testimonial-id') == 2) {
      testimonialText.text('A lot of groups love how fun WiddyUP is and others love getting 95% back. Alex H. from Goodson Middle School, said that WiddyUP was one of the easiest ways to raise money he had come across in his 21 years as a Band Director.')
      testimonialVideo.attr("src", testimonial_3)
      testimonialVideo.attr("poster", testimonial_3_thumb)
      testimonialModal.attr("data-testimonial-id", 3);
      $(".video-info").show();
      hideNextButton()
      showPreviousButton()
      return true;
    }
  })

  $('.step-box').on('click', function (e) {
    let text = $(this).find("span");
    if (text.is(":hidden")) {
      text.show();
    } else {
      text.hide();
    }
  });

});

function hidePreviousButton(){
  $('#testimonial-previous').css('visibility', 'hidden')
}

function hideNextButton(){
  $('#testimonial-next').css('visibility', 'hidden')
}

function showPreviousButton(){
  $('#testimonial-previous').css('visibility', 'visible')
}

function showNextButton(){
  $('#testimonial-next').css('visibility', 'visible')
}
